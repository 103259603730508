@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0; 
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
.cardSinborde{
    max-width: auto;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.enrutamiento{
    max-height: 1921px; 
    max-width: auto;
    overflow: hidden;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    max-height: 680px;
    width: 100%;
    background-size: 100% 100%;
}
.text-white{
    max-height: 680px;
    width: 100%;
    background-size: 100% 100%;
}
.contactar{
    background-color:white;
}
.back-fondo-implementa{
    max-height: auto;
    min-height: auto;
}
.home{
    
    background-color: white;
    border: none !important;
    background-size: contain;
    max-height: auto;
    min-height: auto;
    background-size: 100% 100%;

}
.card-beneficio{
    border: none !important;
}
.card{
    max-width: auto;
    max-height: auto;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.texto-caracteristica{
    color: white;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.1em + 1vw + 1vh);
}
.cardSinborde text-white{
    max-width: auto;
}
.product-add-button{
    background-color: white;  
    border: white;
}
.d-subtitulo{
    color: white;  
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(1.5em + 1vw + 1vh);
}
.d-titulo{
    color: white;  
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.2em + 0.5vw + 1vh);
}
.d-titulo-eficacia{
    color: white;  
    font-family: 'Inter-Bold',sans-serif;
    font-weight: bold;
    font-size: calc(0.2em + 0.5vw + 1vh);
}
.d-desgarga{
    color: white;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);
}
.titulo-interfaz{
    color: rgba(14,20,70) !important;
    font-size: calc(1.5em + 1vw + 1vh);
    text-align: center;
}
.boton-formulario{
    text-align: center;
}

h6{
    color: rgba(236,103,111);
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.2em + 0.5vw + 1vh);
}

.tituloTipoPlan{
    color: rgba(236,103,111);
    font-family: 'Inter-Bold',sans-serif;
    text-align: center;
    font-size: calc(0.2em + 0.5vw + 1vh);
}
.subtituloTipoPlan{
    color: white;
    font-family: 'Inter-Bold',sans-serif;
    text-align: center;
    font-size: calc(1.5em + 1vw + 1vh);

}
.buscarplan{
    color: white;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.2em + 1vw);
}
.buscarplan1{
    color: white;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.1em + 1vw);
    font-style: oblique;
}
.tipoplan{
    background-color: white;
    border:rgba(236,103,111);
    color: black;
    font-size: calc(0.4em + 1vw + 1vh);
}
h2{
    font-weight: bold;
    color:black;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(1.5em + 1vw + 1vh);
}
.card-title-pregunta{
    color: white;
    font-family: 'Inter-SemiBold',sans-serif; 
}

.column-menu-caracteristicas{
    font-family: 'Inter-Bold',sans-serif; 
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: calc(0.5em + 0.5vw + 1vh);
}
.card-title-inicio{
    font-weight: bold;
    color:black;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(1.8em + 1vw + 1vh); 
    text-align: justify;
}
.subtitulo-enrutamiento{
    font-family: 'Inter-SemiBold',sans-serif;
    font-size: calc(1em + 1vw + 1vh); 
}
.card-title-servicio{
    color:black;
    font-family: 'Inter-SemiBold',sans-serif;
    font-size: calc(1em + 1vw + 1vh);
}
.subtitulo-pantalla{
    color:black;
    font-family: 'Inter-SemiBold',sans-serif;
    font-size: calc(1em + 1vw + 1vh);
}
.card-text-servicio{
    text-align: justify;
    color:  rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);

}
.planifique-prueba{
    font-family: 'Inter-SemiBold',sans-serif; 
    color:black;
    font-size: calc(1.5em + 1vw + 1vh);
}

.parrafo{
    text-align: justify;
    color:  rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);
}

.img-responsive-boton4{
    margin:auto;
    height: auto;
    max-height: 20vw;
    max-width: 20vw;
}

.buscarplan{
    text-align: justify;
    color: white;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);
}
.subtituloFuncion{
    color: rgb(22, 21, 21);
    font-family: 'Inter-Bold',sans-serif;
}
.parrafo-enrutamiento{
    text-align: justify;
    font-family: 'Inter-Regular',sans-serif;
    color: white;
    font-size: calc(0.2em + 1vw + 1vh);
}
.lista-enrutamiento{
    text-align: justify;
    font-family: 'Inter-Regular',sans-serif;
    color: white;
    font-size: calc(0.2em + 1vw + 1vh);

}

.nota{
    text-align: justify;
    font-style: oblique;
    color:rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw);
}

.colum-menu{
    font-weight: bold;
    color: rgb(39, 38, 38); 
}
.cardSinborde{
    border:white;  
    }
.titulo-pantalla{
    text-align: center;
    font-family: 'Inter-Bold',sans-serif;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: calc(0.2em + 1vw);
    }
.subtitulo-pantalla{
    text-align: center;
    font-weight: bold;
    font-family: 'Inter-Bold',sans-serif;
    }
.subtitulo-prueba{
        color:rgba(14,20,70) !important;
        text-align: center;
        font-weight: bold;
        font-family: 'Inter-Bold',sans-serif;
        font-size: calc(1.5em + 1vw + 1vh);
        }
.plan-prueba{
    border:1px solid rgb(219, 217, 217); 
    font-weight: bold;
}
.buscar-prueba{
    font-weight: bold;
    font-family: 'Inter-Bold',sans-serif;
    color: rgb(54, 54, 54);
    
}
.card-title{
    font-family: 'Inter-Bold',sans-serif;
}
.card-title-pedido{
    font-weight: bold;
    text-align: center;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.2em + 0.5vw + 1vh);
    color: white;
    

}
.card-title-pregunta{
    font-weight: bold;
    text-align: center;
    font-size: calc(1em + 1vw + 1vh);
}
.card-subtitle-pedido{
    font-family: 'Inter-SemiBold',sans-serif; 
    font-size: calc(1.5em + 1vw + 1vh);
    color: white;


}
.card-text-pedido{
    text-align: justify;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw + 1vh);
    color: white;
}

.registrar{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    align-items:center;

}
.plan{
    background: none !important; 
    color: black;
}
.buscas{
    color: rgb(94, 92, 92);
    font-weight: bold;
    font-size: calc(0.1em + 0.8vw + 1vh);
}
.plan{
    border:1px solid rgb(182, 106, 111);   
}
.control-label{
    font-family: 'Inter-Bold',sans-serif;
    color:rgb(63, 62, 62);
    font-size: calc(0.3em + 1vw + 1vh);
}
input::-webkit-input-placeholder {
    font-family: 'Inter-ExtraLight',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
    }
input:-ms-input-placeholder {
    font-family: 'Inter-ExtraLight',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
    }
input::placeholder {
    font-family: 'Inter-ExtraLight',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
    }
textarea::-webkit-input-placeholder {
        font-family: 'Inter-ExtraLight',sans-serif;
        font-size: calc(0.3em + 1vw + 1vh)
        }
textarea:-ms-input-placeholder {
        font-family: 'Inter-ExtraLight',sans-serif;
        font-size: calc(0.3em + 1vw + 1vh)
        }
textarea::placeholder {
        font-family: 'Inter-ExtraLight',sans-serif;
        font-size: calc(0.3em + 1vw + 1vh)
        }
input[type="text"]{
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
    }
select[type="text"]{
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
    }
textarea[type="text"]{
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
    }
input{
        font-family: 'Inter-Regular',sans-serif;
        font-size: calc(0.3em + 1vw + 1vh)
    }
#nombreCompleto{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#correoCompleto{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#telefonoCompleto{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh); 
}
#empresaCompleto{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#ciudadCompleto{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#giroComercial{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#comentario{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}

#nombreCliente{
    color: rgb(128,128,128);
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#nombreClientep{
    color: rgb(128,128,128);
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}
#apellidoCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#apellidoClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}
#telefonoCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#telefonoClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}
#correoCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#correoClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
    
}
#empresaCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#empresaClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}
#estadoCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#estadoClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}
#rutasCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#rutasClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}


#nombre{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)
}
#telefono{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)
}
#correo{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)
}
#asunto{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)
}
#vendedores{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)

}
#plan{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)

}

.titulo-caracteristicas{
    text-align: center;
    font-size: calc(0.2em + 0.5vw + 1vh);

}
.colum-menu{
    color: white;
    text-align: center;
}
.colum-menu7{
    color: black;
    text-align: center;
    font-family: 'Inter-Bold',sans-serif;
    font-weight: bold;
    font-size: calc(0.3em + 0.5vw + 1vh);
}
.cart{
    
    text-align: center;
    font-family: 'Inter-Regular',sans-serif;
}
.p-1{
    text-align: center; 
}
.card-titulo-beneficios{
    font-weight: bold;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.4em + 0.5vw + 1vh);
}
.subtitulo-enrutamiento{
   color: white;
   font-family: 'Inter-Bold',sans-serif;
   font-size: calc(1em + 1vw + 1vh);
}
.tipoPlan{
    font-family: 'Inter-SemiBold',sans-serif; 
    font-size: calc(0.3em + 1vw + 1vh);
}
.caracPlan{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.2em + 1vw + 1vh);
    text-align: left;
}

.pagoPlan{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.1em + 1vw + 1vh);
}
.pagoPlan2{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.1em + 0.5vw + 1vh);
}
.textplan{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.1em + 1vw + 1vh);
    text-align: left;
}
.textplanp{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.1em + 1vw + 1vh);
    text-align: justify;
}
.parrafoFuncion{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.3em + 1vw + 1vh);
    text-align: justify;
}
.peso{
    font-family: 'Inter-Bold',sans-serif;
    font-weight: bold;
    font-size: calc(1.5em + 1vw + 1vh);
    color:  rgb(65, 63, 63);
}
.tituloSoftware{
    text-align: center;
    color: white;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(1.5em + 1vw + 1vh);

}
.sutituloSoftware{
    color: white;
    text-align: center;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);
}
.contactar{
    align-items: center;
    text-align: center;
}
.titulo-contactar{
    color:  rgb(65, 63, 63);
    font-size: calc(0.5em + 1vw + 1vh);

}

  .gris{
    background-color: rgb(240, 240, 240);
 
  }

  .container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;

    
    }
  @media(max-width: 1030px){
  .cardSinborde{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
    }
    }
  @media(max-width: 1030px){
   .container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    
    }
    }

  img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
    }

 .cards_item {
 display: flex;
 padding: 1rem;
      }

    .card-img-overlay
    {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

.text-white{
    background-color: rgba(244,248,252);
    background-position: center; 
    background-size: cover;
}
.footer-funcion{
 background-color: rgba(38,42,54);
}
.text-funcion{
    color: white;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw + 1vh);

}
.f-facebook{
    color: white;
    font-size: calc(1em + 1vw + 1vh);

} 
.f-ubicacion{
    color: white;
    font-size: calc(1em + 1vw + 1vh);  
}
.f-telefono{
    color: white;
    font-size: calc(1em + 1vw + 1vh);
}
.f-telefono1{
    color: white;
    font-size: calc(1em + 1vw + 1vh);
}
.f-url{
    color: white;
    font-size: calc(1em + 1vw + 1vh); 
}
.nav-link{
    color: rgba(236,103,111);
}

nav ul a:hover li{
    color: 1px solid rgba(236,103,111) !important;
  }

.f-hr{
    color: white;

}

.f-messenger2{
    color: white;  
    font-size: calc(1em + 1vw + 1vh);
}
.f-instagram3{
    color: white;  
    font-size: calc(1em + 1vw + 1vh);
}
.piefuncion{
    color: white;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw + 1vh);

}
p{
    text-align: justify;
    font-family: 'Inter-Regular',sans-serif;
    
}

li{
    text-align: justify;
    font-family: 'Inter-Regular',sans-serif;
}
h6{
    font-weight: bold;
    color: rgba(236,103,111);
    font-family: 'Inter-Regular',sans-serif;
}
h3{
    font-weight: bold;
}
.parrafo1{
    color: rgb(128,128,128);  
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw + 1vh);
     
}
.parrafo2{
    color: black;
}
.titulof{
    color: black;
    font-weight: bold;
    font-size: calc(0.1em + 1vw + 1vh);
    font-family: 'Inter-Regular',sans-serif;

}
.facebook{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgba(14,20,70) !important;
    font-size: calc(0.3em + 1vw + 1vh);
}
.whatsapp{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgba(14,20,70) !important;
    font-size: calc(0.3em + 1vw + 1vh)
}
.messenger{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgba(14,20,70) !important;
    font-size: calc(0.3em + 1vw + 1vh)
}
.instagram{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgba(14,20,70) !important;
    font-size: calc(0.3em + 1vw + 1vh)
}
.pie{
    color: rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.1em + 1vw + 1vh)
}
.atti{
    color: rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw + 1vh)
}


.navbar-logo {  
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
   
}
.titulo-funcion{
    color:white !important;
    text-align: center;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(1.5em + 1vw + 1vh)
}

.logo-emonedero{
    height: auto;
    max-height:30vw;
    max-width: 30vw;
    vertical-align: middle;
}
.vertical-line{ 
    border-left: 2px solid white;
    height:110px;
    position: absolute;
    overflow:hidden;
    left: 50%;
    margin-left: 14px;
    top: 270px;
}

.home{
background-color: rgb(244,248,252);
}

.home-active{
    color: rgba(236,103,111) !important; 
}

.l-home{
    color:rgb(49, 49, 49) !important;
}

.l-home:hover{
    color: rgba(236,103,111) !important; 
}

.l-funcion{
    color:rgba(14,20,70) !important;
}
.l-funcion:hover{
    color: white !important ;
}

.n-home{
 background-color: rgb(238, 245, 252);
}

.cart-length{
margin: 0px;
font-size: calc(0.1em + 1vw);
font-weight: 700;
vertical-align: super;
font-family: 'Inter-Regular',sans-serif;
}

.plan-cart-length{
    margin: 0px;
    font-size: calc(0.1em + 1vw);
    font-weight: 700;
    vertical-align: super;
    color:rgb(80, 78, 78);  
    font-family: 'Inter-Regular',sans-serif; 
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
    font-family: 'Inter-Regular',sans-serif;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
    font-family: 'Inter-Regular',sans-serif;
}

.nav-links{
    color: rgb(128,128,128) !important;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-family: 'Inter-Regular',sans-serif;
    
}
.nav-link{
    color:  rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.5em + 1vw );
}
.nav-link:hover{
    color: rgb(128,128,128);
}

.cart{
    font-size: calc(0.5em + 1vw)
}

.fa-bars {
    color: rgb(128,128,128);;  
}
.nav-links-mobile {
    display: none;
}
.boton-probar{
    height: auto;
    max-height:14vw;
    max-width: 14vw;
    vertical-align: middle;
}
.menu-icon {
    display: none;
}
@media screen and (max-width: 960px){
   .NavbarItems{
       position: relative;
   }
   .nav-menu{
       display: flex;
       flex-direction: column;
       width: 100%;
       height: 500px;
       position: absolute;
       top: 80px;
       left: -100%;
       opacity: 1;
       transition: all 0.5s ease;

   }
   .nav-menu.active {
       background-color: #6668f4;
       left: 0;
       opacity: 1;
       transition: all 0.5s ease;
       z-index: 1;

   }
   .nav-links{
       text-align: center;
       padding: 2rem;
       width: 100%;
       display: table;
   }
   .nav-links:hover{
       background-color: #7577fa;
       border-radius: 0;
   }

   .nav-logo {
       position: absolute;
       top: 0;
       left: 0;
       -webkit-transform: translate(25%,50%);
               transform: translate(25%,50%);
   }
 
   .menu-icon {
       display: rgb(128,128,128);
       position: absolute;
       right: 0;
       -webkit-transform: translate(-100%, 60%);
               transform: translate(-100%, 60%);
       font-size: 1.8rem;
       cursor: pointer;
   }  
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    }

.n-funcion{
    background-color: rgb(244,248,252);
    min-height: 380px;
    }
.n-plan{
    min-height: 380px;  
}
.n-contacto{
    min-height: 380px;   
}

.parrafo2{
    text-align:justify;
    color:rgb(128,128,128);
    font-family:'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
}
.card-titulo{
    font-weight:bold;
    color: rgb(78, 77, 77);
}
.card-link{
    font-weight:bold;
    color:rgb(128,128,128);
}
.card-subtitle-beneficios{
    font-family:'Inter-SemiBold',sans-serif;
    color:black;
    font-size: calc(1.5em + 1vw + 1vh);

}
.card-text-beneficios{
    text-align:justify;
    color:rgb(128,128,128);
    font-family:'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);
}

.cart-items{
    margin: 30px auto;
    width: 50%;
    background-color:rgba(244,248,252);
    border: none;
    border-radius: 5px;
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
}
.cart-items-header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    border-bottom: gray;
    font-family: 'Inter-Bold',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.5em + 1vw + 1vh);

}
.cart-items-empty{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(0.4em + 1vw + 1vh);
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Inter-Bold',sans-serif;
    color:  rgb(128,128,128);

}

.cart-items-list{
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    margin-left: 5%;
    font-family: 'Inter-Regular',sans-serif;
}
.cart-items-name,
.cart-items-function {
    width: 25%;
    font-size: calc(0.3em + 1vw + 1vh)

}

.cart-items-add,
.cart-items-remove{
    width: calc(0.5em + 1vw + 1vh);
    margin-right: 15px;
    font-weight: 700;
    border: gray;
    border-radius: 3px;
    font-size: calc(0.3em + 1vw + 1vh);
    color: white;
    cursor: pointer;
    font-family: 'Inter-Regular',sans-serif;
}
.cart-items-add{
    background-color:red;
}
.cart-items-price{
    font-size: calc(0.3em + 1vw + 1vh);
    color:  rgb(128,128,128);
}
.cart-items-remove{
    background-color: green;
}

.cart-items-add:active{
    -webkit-transform: translateY(1.2px);
            transform: translateY(1.2px);
}
.cart-items-remove:active{
    -webkit-transform: translateY(1.2px);
            transform: translateY(1.2px);
}
.cart-items-total-price-name{
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 22%;
    font-size: calc(0.3em + 1vw + 1vh);
    font-weight: 700;
    border-top: gray;
    font-family: 'Inter-Regular',sans-serif;
}

.cart-items-total-price{
    display: flex;
    padding-left: 45%;
    font-family: 'Inter-Regular',sans-serif;
}
.clear-cart{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 10px;
}

.clear-cart-button{
    border: none;
    border-radius: 5px;
    background-color: #bd0e0e;
    height: calc(0.5em + 1vw + 1vh);
    font-size: calc(0.3em + 1vw + 1vh);
    color:white;
    cursor: pointer;
}
.clear-cart-button:hover{
    background-color: #0e557e;
}
.paypal{
    justify-content: center;
    align-items: center;
    align:center;
    display: flex;
}
.monto{
    justify-content: center;
    align-items: center;
    align:center;
    font-size: calc(1em + 0.9vw + 1vh);
    font-family: 'Inter-Regular',sans-serif;
    display: flex; 
}
@media(max-width: 1030px){
.container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
}
}
