
.navbar-logo {  
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
   
}
.titulo-funcion{
    color:white !important;
    text-align: center;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(1.5em + 1vw + 1vh)
}

.logo-emonedero{
    height: auto;
    max-height:30vw;
    max-width: 30vw;
    vertical-align: middle;
}
.vertical-line{ 
    border-left: 2px solid white;
    height:110px;
    position: absolute;
    overflow:hidden;
    left: 50%;
    margin-left: 14px;
    top: 270px;
}

.home{
background-color: rgb(244,248,252);
}

.home-active{
    color: rgba(236,103,111) !important; 
}

.l-home{
    color:rgb(49, 49, 49) !important;
}

.l-home:hover{
    color: rgba(236,103,111) !important; 
}

.l-funcion{
    color:rgba(14,20,70) !important;
}
.l-funcion:hover{
    color: white !important ;
}

.n-home{
 background-color: rgb(238, 245, 252);
}

.cart-length{
margin: 0px;
font-size: calc(0.1em + 1vw);
font-weight: 700;
vertical-align: super;
font-family: 'Inter-Regular',sans-serif;
}

.plan-cart-length{
    margin: 0px;
    font-size: calc(0.1em + 1vw);
    font-weight: 700;
    vertical-align: super;
    color:rgb(80, 78, 78);  
    font-family: 'Inter-Regular',sans-serif; 
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
    font-family: 'Inter-Regular',sans-serif;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
    font-family: 'Inter-Regular',sans-serif;
}

.nav-links{
    color: rgb(128,128,128) !important;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-family: 'Inter-Regular',sans-serif;
    
}
.nav-link{
    color:  rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.5em + 1vw );
}
.nav-link:hover{
    color: rgb(128,128,128);
}

.cart{
    font-size: calc(0.5em + 1vw)
}

.fa-bars {
    color: rgb(128,128,128);;  
}
.nav-links-mobile {
    display: none;
}
.boton-probar{
    height: auto;
    max-height:14vw;
    max-width: 14vw;
    vertical-align: middle;
}
.menu-icon {
    display: none;
}
@media screen and (max-width: 960px){
   .NavbarItems{
       position: relative;
   }
   .nav-menu{
       display: flex;
       flex-direction: column;
       width: 100%;
       height: 500px;
       position: absolute;
       top: 80px;
       left: -100%;
       opacity: 1;
       transition: all 0.5s ease;

   }
   .nav-menu.active {
       background-color: #6668f4;
       left: 0;
       opacity: 1;
       transition: all 0.5s ease;
       z-index: 1;

   }
   .nav-links{
       text-align: center;
       padding: 2rem;
       width: 100%;
       display: table;
   }
   .nav-links:hover{
       background-color: #7577fa;
       border-radius: 0;
   }

   .nav-logo {
       position: absolute;
       top: 0;
       left: 0;
       transform: translate(25%,50%);
   }
 
   .menu-icon {
       display: rgb(128,128,128);
       position: absolute;
       right: 0;
       transform: translate(-100%, 60%);
       font-size: 1.8rem;
       cursor: pointer;
   }  
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    }

.n-funcion{
    background-color: rgb(244,248,252);
    min-height: 380px;
    }
.n-plan{
    min-height: 380px;  
}
.n-contacto{
    min-height: 380px;   
}
