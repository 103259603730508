@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


.text-white{
    background-color: rgba(244,248,252);
    background-position: center; 
    background-size: cover;
}
.footer-funcion{
 background-color: rgba(38,42,54);
}
.text-funcion{
    color: white;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw + 1vh);

}
.f-facebook{
    color: white;
    font-size: calc(1em + 1vw + 1vh);

} 
.f-ubicacion{
    color: white;
    font-size: calc(1em + 1vw + 1vh);  
}
.f-telefono{
    color: white;
    font-size: calc(1em + 1vw + 1vh);
}
.f-telefono1{
    color: white;
    font-size: calc(1em + 1vw + 1vh);
}
.f-url{
    color: white;
    font-size: calc(1em + 1vw + 1vh); 
}
.nav-link{
    color: rgba(236,103,111);
}

nav ul a:hover li{
    color: 1px solid rgba(236,103,111) !important;
  }

.f-hr{
    color: white;

}

.f-messenger2{
    color: white;  
    font-size: calc(1em + 1vw + 1vh);
}
.f-instagram3{
    color: white;  
    font-size: calc(1em + 1vw + 1vh);
}
.piefuncion{
    color: white;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw + 1vh);

}
p{
    text-align: justify;
    font-family: 'Inter-Regular',sans-serif;
    
}

li{
    text-align: justify;
    font-family: 'Inter-Regular',sans-serif;
}
h6{
    font-weight: bold;
    color: rgba(236,103,111);
    font-family: 'Inter-Regular',sans-serif;
}
h3{
    font-weight: bold;
}
.parrafo1{
    color: rgb(128,128,128);  
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw + 1vh);
     
}
.parrafo2{
    color: black;
}
.titulof{
    color: black;
    font-weight: bold;
    font-size: calc(0.1em + 1vw + 1vh);
    font-family: 'Inter-Regular',sans-serif;

}
.facebook{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgba(14,20,70) !important;
    font-size: calc(0.3em + 1vw + 1vh);
}
.whatsapp{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgba(14,20,70) !important;
    font-size: calc(0.3em + 1vw + 1vh)
}
.messenger{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgba(14,20,70) !important;
    font-size: calc(0.3em + 1vw + 1vh)
}
.instagram{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgba(14,20,70) !important;
    font-size: calc(0.3em + 1vw + 1vh)
}
.pie{
    color: rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.1em + 1vw + 1vh)
}
.atti{
    color: rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw + 1vh)
}
