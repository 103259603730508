.cart-items{
    margin: 30px auto;
    width: 50%;
    background-color:rgba(244,248,252);
    border: none;
    border-radius: 5px;
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
}
.cart-items-header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    border-bottom: gray;
    font-family: 'Inter-Bold',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.5em + 1vw + 1vh);

}
.cart-items-empty{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(0.4em + 1vw + 1vh);
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Inter-Bold',sans-serif;
    color:  rgb(128,128,128);

}

.cart-items-list{
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    margin-left: 5%;
    font-family: 'Inter-Regular',sans-serif;
}
.cart-items-name,
.cart-items-function {
    width: 25%;
    font-size: calc(0.3em + 1vw + 1vh)

}

.cart-items-add,
.cart-items-remove{
    width: calc(0.5em + 1vw + 1vh);
    margin-right: 15px;
    font-weight: 700;
    border: gray;
    border-radius: 3px;
    font-size: calc(0.3em + 1vw + 1vh);
    color: white;
    cursor: pointer;
    font-family: 'Inter-Regular',sans-serif;
}
.cart-items-add{
    background-color:red;
}
.cart-items-price{
    font-size: calc(0.3em + 1vw + 1vh);
    color:  rgb(128,128,128);
}
.cart-items-remove{
    background-color: green;
}

.cart-items-add:active{
    transform: translateY(1.2px);
}
.cart-items-remove:active{
    transform: translateY(1.2px);
}
.cart-items-total-price-name{
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 22%;
    font-size: calc(0.3em + 1vw + 1vh);
    font-weight: 700;
    border-top: gray;
    font-family: 'Inter-Regular',sans-serif;
}

.cart-items-total-price{
    display: flex;
    padding-left: 45%;
    font-family: 'Inter-Regular',sans-serif;
}
.clear-cart{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 10px;
}

.clear-cart-button{
    border: none;
    border-radius: 5px;
    background-color: #bd0e0e;
    height: calc(0.5em + 1vw + 1vh);
    font-size: calc(0.3em + 1vw + 1vh);
    color:white;
    cursor: pointer;
}
.clear-cart-button:hover{
    background-color: #0e557e;
}
.paypal{
    justify-content: center;
    align-items: center;
    align:center;
    display: flex;
}
.monto{
    justify-content: center;
    align-items: center;
    align:center;
    font-size: calc(1em + 0.9vw + 1vh);
    font-family: 'Inter-Regular',sans-serif;
    display: flex; 
}
@media(max-width: 1030px){
.container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
}
}