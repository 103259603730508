@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


.cardSinborde{
    max-width: auto;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.enrutamiento{
    max-height: 1921px; 
    max-width: auto;
    overflow: hidden;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    max-height: 680px;
    width: 100%;
    background-size: 100% 100%;
}
.text-white{
    max-height: 680px;
    width: 100%;
    background-size: 100% 100%;
}
.contactar{
    background-color:white;
}
.back-fondo-implementa{
    max-height: auto;
    min-height: auto;
}
.home{
    
    background-color: white;
    border: none !important;
    background-size: contain;
    max-height: auto;
    min-height: auto;
    background-size: 100% 100%;

}
.card-beneficio{
    border: none !important;
}
.card{
    max-width: auto;
    max-height: auto;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.texto-caracteristica{
    color: white;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.1em + 1vw + 1vh);
}
.cardSinborde text-white{
    max-width: auto;
}
.product-add-button{
    background-color: white;  
    border: white;
}
.d-subtitulo{
    color: white;  
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(1.5em + 1vw + 1vh);
}
.d-titulo{
    color: white;  
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.2em + 0.5vw + 1vh);
}
.d-titulo-eficacia{
    color: white;  
    font-family: 'Inter-Bold',sans-serif;
    font-weight: bold;
    font-size: calc(0.2em + 0.5vw + 1vh);
}
.d-desgarga{
    color: white;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);
}
.titulo-interfaz{
    color: rgba(14,20,70) !important;
    font-size: calc(1.5em + 1vw + 1vh);
    text-align: center;
}
.boton-formulario{
    text-align: center;
}

h6{
    color: rgba(236,103,111);
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.2em + 0.5vw + 1vh);
}

.tituloTipoPlan{
    color: rgba(236,103,111);
    font-family: 'Inter-Bold',sans-serif;
    text-align: center;
    font-size: calc(0.2em + 0.5vw + 1vh);
}
.subtituloTipoPlan{
    color: white;
    font-family: 'Inter-Bold',sans-serif;
    text-align: center;
    font-size: calc(1.5em + 1vw + 1vh);

}
.buscarplan{
    color: white;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.2em + 1vw);
}
.buscarplan1{
    color: white;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.1em + 1vw);
    font-style: oblique;
}
.tipoplan{
    background-color: white;
    border:rgba(236,103,111);
    color: black;
    font-size: calc(0.4em + 1vw + 1vh);
}
h2{
    font-weight: bold;
    color:black;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(1.5em + 1vw + 1vh);
}
.card-title-pregunta{
    color: white;
    font-family: 'Inter-SemiBold',sans-serif; 
}

.column-menu-caracteristicas{
    font-family: 'Inter-Bold',sans-serif; 
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: calc(0.5em + 0.5vw + 1vh);
}
.card-title-inicio{
    font-weight: bold;
    color:black;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(1.8em + 1vw + 1vh); 
    text-align: justify;
}
.subtitulo-enrutamiento{
    font-family: 'Inter-SemiBold',sans-serif;
    font-size: calc(1em + 1vw + 1vh); 
}
.card-title-servicio{
    color:black;
    font-family: 'Inter-SemiBold',sans-serif;
    font-size: calc(1em + 1vw + 1vh);
}
.subtitulo-pantalla{
    color:black;
    font-family: 'Inter-SemiBold',sans-serif;
    font-size: calc(1em + 1vw + 1vh);
}
.card-text-servicio{
    text-align: justify;
    color:  rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);

}
.planifique-prueba{
    font-family: 'Inter-SemiBold',sans-serif; 
    color:black;
    font-size: calc(1.5em + 1vw + 1vh);
}

.parrafo{
    text-align: justify;
    color:  rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);
}

.img-responsive-boton4{
    margin:auto;
    height: auto;
    max-height: 20vw;
    max-width: 20vw;
}

.buscarplan{
    text-align: justify;
    color: white;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);
}
.subtituloFuncion{
    color: rgb(22, 21, 21);
    font-family: 'Inter-Bold',sans-serif;
}
.parrafo-enrutamiento{
    text-align: justify;
    font-family: 'Inter-Regular',sans-serif;
    color: white;
    font-size: calc(0.2em + 1vw + 1vh);
}
.lista-enrutamiento{
    text-align: justify;
    font-family: 'Inter-Regular',sans-serif;
    color: white;
    font-size: calc(0.2em + 1vw + 1vh);

}

.nota{
    text-align: justify;
    font-style: oblique;
    color:rgb(128,128,128);
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw);
}

.colum-menu{
    font-weight: bold;
    color: rgb(39, 38, 38); 
}
.cardSinborde{
    border:white;  
    }
.titulo-pantalla{
    text-align: center;
    font-family: 'Inter-Bold',sans-serif;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: calc(0.2em + 1vw);
    }
.subtitulo-pantalla{
    text-align: center;
    font-weight: bold;
    font-family: 'Inter-Bold',sans-serif;
    }
.subtitulo-prueba{
        color:rgba(14,20,70) !important;
        text-align: center;
        font-weight: bold;
        font-family: 'Inter-Bold',sans-serif;
        font-size: calc(1.5em + 1vw + 1vh);
        }
.plan-prueba{
    border:1px solid rgb(219, 217, 217); 
    font-weight: bold;
}
.buscar-prueba{
    font-weight: bold;
    font-family: 'Inter-Bold',sans-serif;
    color: rgb(54, 54, 54);
    
}
.card-title{
    font-family: 'Inter-Bold',sans-serif;
}
.card-title-pedido{
    font-weight: bold;
    text-align: center;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.2em + 0.5vw + 1vh);
    color: white;
    

}
.card-title-pregunta{
    font-weight: bold;
    text-align: center;
    font-size: calc(1em + 1vw + 1vh);
}
.card-subtitle-pedido{
    font-family: 'Inter-SemiBold',sans-serif; 
    font-size: calc(1.5em + 1vw + 1vh);
    color: white;


}
.card-text-pedido{
    text-align: justify;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.2em + 1vw + 1vh);
    color: white;
}

.registrar{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    align-items:center;

}
.plan{
    background: none !important; 
    color: black;
}
.buscas{
    color: rgb(94, 92, 92);
    font-weight: bold;
    font-size: calc(0.1em + 0.8vw + 1vh);
}
.plan{
    border:1px solid rgb(182, 106, 111);   
}
.control-label{
    font-family: 'Inter-Bold',sans-serif;
    color:rgb(63, 62, 62);
    font-size: calc(0.3em + 1vw + 1vh);
}
input::placeholder {
    font-family: 'Inter-ExtraLight',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
    }
textarea::placeholder {
        font-family: 'Inter-ExtraLight',sans-serif;
        font-size: calc(0.3em + 1vw + 1vh)
        }
input[type="text"]{
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
    }
select[type="text"]{
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
    }
textarea[type="text"]{
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
    }
input{
        font-family: 'Inter-Regular',sans-serif;
        font-size: calc(0.3em + 1vw + 1vh)
    }
#nombreCompleto{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#correoCompleto{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#telefonoCompleto{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh); 
}
#empresaCompleto{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#ciudadCompleto{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#giroComercial{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#comentario{
    font-family: 'Inter-Regular',sans-serif;
    color: rgb(128,128,128);
    font-size: calc(0.1em + 0.8vw + 1vh);
}

#nombreCliente{
    color: rgb(128,128,128);
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.1em + 0.8vw + 1vh);
}
#nombreClientep{
    color: rgb(128,128,128);
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}
#apellidoCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#apellidoClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}
#telefonoCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#telefonoClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}
#correoCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#correoClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
    
}
#empresaCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#empresaClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}
#estadoCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#estadoClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}
#rutasCliente{
    border-radius: 20px;
    border:1px solid rgba(236,103,111);
    font-size: calc(0.2em + 0.8vw + 1vh);
}
#rutasClientep{
    border-radius: 20px;
    font-size: calc(0.1em + 0.7vw + 1vh);
}


#nombre{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)
}
#telefono{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)
}
#correo{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)
}
#asunto{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)
}
#vendedores{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)

}
#plan{
    border-radius: 20px;
    font-size: calc(0.1em + 1vw + 1vh)

}

.titulo-caracteristicas{
    text-align: center;
    font-size: calc(0.2em + 0.5vw + 1vh);

}
.colum-menu{
    color: white;
    text-align: center;
}
.colum-menu7{
    color: black;
    text-align: center;
    font-family: 'Inter-Bold',sans-serif;
    font-weight: bold;
    font-size: calc(0.3em + 0.5vw + 1vh);
}
.cart{
    
    text-align: center;
    font-family: 'Inter-Regular',sans-serif;
}
.p-1{
    text-align: center; 
}
.card-titulo-beneficios{
    font-weight: bold;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(0.4em + 0.5vw + 1vh);
}
.subtitulo-enrutamiento{
   color: white;
   font-family: 'Inter-Bold',sans-serif;
   font-size: calc(1em + 1vw + 1vh);
}
.tipoPlan{
    font-family: 'Inter-SemiBold',sans-serif; 
    font-size: calc(0.3em + 1vw + 1vh);
}
.caracPlan{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.2em + 1vw + 1vh);
    text-align: left;
}

.pagoPlan{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.1em + 1vw + 1vh);
}
.pagoPlan2{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.1em + 0.5vw + 1vh);
}
.textplan{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.1em + 1vw + 1vh);
    text-align: left;
}
.textplanp{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.1em + 1vw + 1vh);
    text-align: justify;
}
.parrafoFuncion{
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
    font-size: calc(0.3em + 1vw + 1vh);
    text-align: justify;
}
.peso{
    font-family: 'Inter-Bold',sans-serif;
    font-weight: bold;
    font-size: calc(1.5em + 1vw + 1vh);
    color:  rgb(65, 63, 63);
}
.tituloSoftware{
    text-align: center;
    color: white;
    font-family: 'Inter-Bold',sans-serif;
    font-size: calc(1.5em + 1vw + 1vh);

}
.sutituloSoftware{
    color: white;
    text-align: center;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);
}
.contactar{
    align-items: center;
    text-align: center;
}
.titulo-contactar{
    color:  rgb(65, 63, 63);
    font-size: calc(0.5em + 1vw + 1vh);

}

  .gris{
    background-color: rgb(240, 240, 240);
 
  }

  .container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;

    
    }
  @media(max-width: 1030px){
  .cardSinborde{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
    }
    }
  @media(max-width: 1030px){
   .container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    
    }
    }

  img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
    }

 .cards_item {
 display: flex;
 padding: 1rem;
      }

    .card-img-overlay
    {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
