@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.parrafo2{
    text-align:justify;
    color:rgb(128,128,128);
    font-family:'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
}
.card-titulo{
    font-weight:bold;
    color: rgb(78, 77, 77);
}
.card-link{
    font-weight:bold;
    color:rgb(128,128,128);
}
.card-subtitle-beneficios{
    font-family:'Inter-SemiBold',sans-serif;
    color:black;
    font-size: calc(1.5em + 1vw + 1vh);

}
.card-text-beneficios{
    text-align:justify;
    color:rgb(128,128,128);
    font-family:'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);
}
